import React from "react";
import Helmet from "react-helmet";
import { Content } from "components/Content";
import { TextBlock } from "components/TextBlock";
import { List } from "components/List";
import { InfoEmail } from "components/Emails";
import { Hero } from "components/Hero";
import { images } from "components/HeroImages";

function JoinPage(p) {
  const title = "Join the Team | Status100 Promotions";
  const description =
    "We've Hosted Events involving: Davido, Tion Wayne, Burna Boy, DJ Maphorisa, DBN Gogo, Stormzy, and Many More.";

  return (
    <React.Fragment>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={title}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:url`,
            content: p.location.href,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: ``,
          },
        ]}
      />
      <Hero image={images.join}>
        <h1>Join the team</h1>
      </Hero>
      <Content>
        <List>
          <TextBlock>
            <p>
              We've Hosted Events involving: Davido, Tion Wayne, Burna Boy, DJ
              Maphorisa, DBN Gogo, Stormzy, and Many More.
            </p>
          </TextBlock>
          <TextBlock>
            <h4>For more info please email</h4>
            <InfoEmail />
          </TextBlock>
        </List>
      </Content>
    </React.Fragment>
  );
}

export default JoinPage;
